.header1 {
    font-size: 30px;
    font-weight: 500;
}

.header3 {
    font-size: 20px;
    font-weight: 500;
}

.paragraph1 {
    font-size: 18px;
}