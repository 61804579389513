.navbar {
  font-family: "Noto Serif KR", serif;
  font-size: calc(0.5rem + 0.6vw);
}

.container {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 1900px !important;
}

.lastNavLink {
  padding-right: 0 !important;
}

.disabled {
  color: grey !important;
}