.container {
    font-family: "Noto Serif KR", serif;
    padding-top: 20px;
}

.image {
    width: 250px;
    margin-right: 20px;
}

.content {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
}

.cardContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 250px);
    gap: 16px;
    max-width: 800px;
    margin: auto;
}

.card {
    width: 250px;
    height: 250px;
    padding-top: 20px;
    text-align: center;
    color: white;
}

.header {
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
}