.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
}

.header {
    margin-bottom: 10px;
}

.text {
    margin-bottom: 14px;
}