.header {
    padding-top: 20px;
    margin: 0 5%;
    font-family: "Noto Serif KR", serif;
}

.headerContent {
    display: flex;
    justify-content: space-between;
    font-size: calc(0.5rem + 0.6vw);
}

.navbarDivider {
    margin: 10px 0;
}

.bannerText {
    display: flex;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../assets/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Noto Serif Kr", serif;
    width: 100%;
    min-height: 90vh;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    color: white;
    padding: 0 150px;
}

.companyName {
    font-size: calc(0.6rem + 0.6vw);
}

.contact {
    text-align: right;
    font-size: calc(0.4rem + 0.6vw);
}

.verticalDivider {
    margin: 0 80px
}

.divider {
    display: none;
}

.navbarContainer {
    margin-bottom: 10px;
}

.image {
    width: 100%;
}

.title {
    font-size: calc(1.4rem + 0.6vw);
    font-weight: 700;
    margin: 0 0 24px 0;
}

.subtitle {
    font-size: calc(1rem + 0.6vw);
    margin-bottom: 4px;
}

.subtext {
    font-size: calc(1rem + 0.6vw);
    line-height: 1.6;
}

.button {
    margin-top: 20px;
}

@media (max-width: 1140px) {
    .companyName {
        display: none;
    }

    .contact {
        text-align: left;
    }

    .bannerText {
        flex-direction: column;
        padding: 0 5%;
    }

    .divider {
        display: block;
        width: 50%;
    }

    .verticalDivider {
        display: none;
    }
}

@media (max-width: 768px) {
    .navbarDivider {
      display: none;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
  }
